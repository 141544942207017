


















































































import { Game } from '@/api'
import { GameModule } from '@/store/modules'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {
    LeaderboardCard: () => import('@/components/LeaderboardCard.vue'),
    LogsTable: () => import('@/components/Logs/LogsTable.vue'),
    Pagination: () => import('@/components/Pagination.vue'),
    RbbRanking: () => import('./RbbRanking.vue'),
    RaidRanking: () => import('./RaidRanking.vue')
  }
})
export default class Leaderboards extends Vue {
  @GameModule.State characters

  rankingTypes: string[] = []
  rankingData = []
  rankingDataFiltered: any[] = []
  rankingIndex = 0
  loading = false
  units = [
    { type: 'monster', unit: 'monsters killed' },
    { type: 'rbb', unit: 'wins' },
    { type: 'icebreaker', unit: 'players killed' },
    { type: 'raid', unit: 'raids' },
    { type: 'achievements', unit: 'points' },
    { type: 'act4', unit: 'players killed' },
    { type: 'aot', unit: 'wins' },
  ]

  get characterNames() {
    return this.characters.map(e => e.name)
  }

  get tableColumns() {
    return [
      { key: 'rank' },
      { key: 'name', label: 'Character Name' },
      { key: 'class' },
      { key: 'level' },
      { key: 'heroLevel' },
      { key: 'value', label: this.units[this.rankingIndex].unit },
    ]
  }

  currentPage = 1
  perPage = 25
  totalCount = 0

  @Watch('rankingIndex')
  async onTabIndexChange() {
    await this.fetchRankingData()
  }

  updateRankingData(value) {
    this.currentPage = value
    const min = this.currentPage === 1 ? 3 : (this.currentPage - 1) * this.perPage
    const max = this.currentPage ===  1 ? this.perPage : (this.currentPage - 1) * this.perPage + this.perPage

    this.rankingDataFiltered = this.rankingIndex === 1
      ? this.rankingData
      : this.rankingData
        .slice(min, max)
        .map((e: any) => {
          if (this.characterNames.includes(e.name))
            e._rowVariant = 'primary'

          return e
      })
  }

  formatLevel(item) {
    return `${item.level} ${item.heroLevel > 0 ? `(+${ item.heroLevel})` : ''}`
  }

  async mounted() {
    try {
      this.rankingTypes = await Game.getRankingTypes()

      await this.fetchRankingData()
    } catch(e) {
      console.error('Cannot fetch leaderboard data', e)
    }
  }

  async fetchRankingData() {
    try {
      this.loading = true
      this.rankingData = await Game.getRankingOf(this.rankingTypes[this.rankingIndex])
      this.totalCount = this.rankingData.length
      this.updateRankingData(1)
      this.loading = false
    } catch(e) {
      console.error('Cannot fetch leaderboard data', e)
    }
  }
  
  getCurrentUnit(tab) {
    return this.units.find(e => e.type === tab.toLowerCase().slice(4))?.unit
  }
}
